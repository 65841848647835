import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DownloadAppButton from './DownloadAppButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyStart
          alignCenter
        >
          <Header />
          <CFView textCenter column center w="100%" h="40vh" mt="45px">
            <CFView column center mb="30px">
              <CFImage src={mobileHeroText} w="80%" alt="Promotions" />
            </CFView>
            <CFView mb="5px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView>
              <DownloadAppButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          image={`url(${hero}) left/ cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignStart
        >
          <Header />
          <CFView
            w="100%"
            column
            justifyCenter
            alignStart
            ml="45px"
            mb="45px"
            h="100%"
          >
            <CFImage
              src={heroText}
              minWidth="300px"
              maxWidth="600px"
              alt="Promotions"
            />
            <CFView mt="30px">
              <OrderPickupButton />
            </CFView>
            <CFView mv="10px">
              <DownloadAppButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
