import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoWhite, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/NmljFgO1p1b0DnWwM4Lw/locations/Ab8324iqgy4BQgWK2Ufi'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center w="100%" h="70px">
          <CFView row center>
            <CFImage h="60px" mt="10px" src={logoWhite} alt="Maru Sushi Logo" />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" zIndex={98}>
          <CFView row w="100%" maxWidth="1400px" ph="30px">
            <CFView row center mt="15px">
              <CFImage
                h="100px"
                ml="15px"
                src={logo}
                alt="Maru Sushi Logo"
                zIndex={98}
              />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
